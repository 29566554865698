import { ErrorPage } from '../components/ErrorPage';
import {
  ERROR_CONTENT_TYPES,
  ERROR_CONTENT,
} from '../constants/error-page-content';

function Error404Page() {
  return (
    <ErrorPage {...ERROR_CONTENT[ERROR_CONTENT_TYPES.NOT_FOUND_DEFAULT]} />
  );
}

export default Error404Page;
