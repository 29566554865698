import paths from './paths';

export const ERROR_CONTENT_TYPES = {
  NOT_FOUND_DEFAULT: 'default',
  GONE: 'gone',
  NOT_FOUND_SUPPLIER: 'supplier',
};

export const ERROR_CONTENT = {
  [ERROR_CONTENT_TYPES.NOT_FOUND_DEFAULT]: {
    heading: 'Keep calm and try again',
    body: `The page you’re looking for could have been deleted or never
              existed.`,
    button: {
      location: paths.HOME,
      text: 'Home',
    },
    statusCode: 404,
  },
  [ERROR_CONTENT_TYPES.NOT_FOUND_SUPPLIER]: {
    heading: 'Supplier not found',
    body: `Oops, that supplier profile doesn’t seem to exist.`,
    button: {
      location: paths.BROWSE,
      text: 'View more suppliers',
    },
    statusCode: 404,
  },
};
