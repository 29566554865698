import {
  Box,
  Heading,
  Text,
  Container,
  columnWidth,
  Button,
  Flex,
} from '@feast-it/pesto';
import PropTypes from 'prop-types';

import Link from './Link';
import Layout from '../containers/layout';
import Meta from './Meta';
import NotFoundImage from '../public/images/generic/404.svg';

export function ErrorPage({ heading, body, button, statusCode }) {
  return (
    <Layout>
      <Meta subtitle={statusCode} />

      <Box
        py={12}
        px={{
          _: 'gutter._',
          s: 'gutter.s',
          m: 'gutter.m',
        }}
      >
        <Container maxWidth={columnWidth(6)}>
          <Flex flexDirection="column" alignItems="center">
            {statusCode !== 410 && <NotFoundImage />}

            <Heading
              variant={{
                _: 'headingExtraLargeMobile',
                m: 'headingExtraLarge',
              }}
              color="blueDark"
              textAlign="center"
              mt={6}
            >
              {heading}
            </Heading>

            <Text color="greyDark" textAlign="center" mt={4}>
              {body}
            </Text>

            <Button href={button.location} linkComponent={Link} mt={6}>
              {button.text}
            </Button>
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
}

ErrorPage.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  button: PropTypes.shape({
    location: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  statusCode: PropTypes.number.isRequired,
};
